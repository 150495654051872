import { FirebaseAuthentication, SignInResult } from '@capacitor-firebase/authentication'
import { User as FirebaseUser } from "firebase/auth"
import { signOut } from 'firebase/auth'
import {
    getFirebaseAuth,
} from './firebase'
import {
    fetchSignInMethodsForEmail,
    linkWithCredential,
    signInWithCredential,
    unlink,
    EmailAuthProvider,
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    PhoneAuthProvider,
	OAuthCredential,
	PhoneAuthCredential,
	EmailAuthCredential,
	UserCredential,
} from 'firebase/auth'
import { Capacitor } from '@capacitor/core'
import { ucFirst } from './util'
import {
    call,
    logoApple,
    logoFacebook,
    logoGoogle,
    mail,
} from 'ionicons/icons'

type ProviderId = 'apple.com' | 'google.com' | 'facebook.com' | 'github.com' | 'microsoft.com' | 'yahoo.com' | 'phone' | 'password' | 'firebase'

export type Provider = {
	id: ProviderId
	icon: string
	name: string
	type: string
	disabled?: boolean
	link?: boolean | ((props: ProviderLinkWithProps) => Promise<FirebaseUser | UserCredential | null>)
	signIn?: (opt?: any) => Promise<any>
}

type ProviderLinkWithProps = {
	setErr: (err: string|null) => void
}

export const providers: Provider[] = [
    {
        id: 'apple.com',
        name: 'Apple',
        type: 'Apple ID',
        icon: logoApple,
        signIn: signInWithApple,
        link: linkWithApple,
		disabled: Capacitor.getPlatform() === 'android',
    },
    {
        id: 'google.com',
        name: 'Google',
        type: 'Google Account',
        icon: logoGoogle,
        signIn: signInWithGoogle,
        link: linkWithGoogle,
    },
    {
        id: 'facebook.com',
        name: 'Facebook',
        type: 'Facebook Account',
        icon: logoFacebook,
        signIn: signInWithFacebook,
        // link: linkWithFacebook,
    },
    {
        id: 'phone',
        name: 'Phone number',
        type: 'Phone number',
        icon: call,
		link: true,
		// No 'link' function because we have to throw up a modal and get the phone number ourselves.
		// link: linkWithPhoneNumber,
        // disabled: !window?.cordova,
    },
    {
        id: 'password',
        name: 'Email + password',
        type: 'Email address',
        icon: mail,
    },
] as const

export function getProvider(providerId: string) {
    return providers.find(provider => provider.id === providerId)
}

async function signInWithGoogle() {
    let result
    try {
        result = await FirebaseAuthentication.signInWithGoogle()
    } catch (err) {
        return
    }

    const credential = GoogleAuthProvider.credential(result.credential?.idToken)
    try {
        await deviceSignIn(credential)
    } catch (err) {
        console.log('caught err', err)
    }
}

async function signInWithApple() {
    let result

    try {
        console.log("About to call FA.signInWithApple()")
        result = await FirebaseAuthentication.signInWithApple({ skipNativeAuth: true })
        console.log('result', result)
    } catch (err) {
        console.error("Oh noez error", err)
        return
    }

    const provider = new OAuthProvider('apple.com')
    const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce,
    })

    try {
        await deviceSignIn(credential)
    } catch (err) {
        console.log('caught err', err)
    }
}

async function signInWithFacebook() {
    let result: SignInResult

    try {
        result = await FirebaseAuthentication.signInWithFacebook()
    } catch (err) {
		console.error("signInWithFacebook error", err)
        return
    }

	const { accessToken } = result.credential || { }
	if (!accessToken) {
		console.error("signInWithFacebook result missing accessToken", result)
		return
	}

    const credential = FacebookAuthProvider.credential(accessToken)
    try {
        await deviceSignIn(credential)
    } catch (err) {
        console.log('caught err', err)
    }
}

type SignInWithPhoneNumberProps = {
	verificationId: string,
	verificationCode: string,
}

export async function signInWithPhoneNumber(props: SignInWithPhoneNumberProps) {
	const { verificationId, verificationCode } = props

    console.log("Calling signInWithPhoneNumber", verificationId, verificationCode)

    const credential = PhoneAuthProvider.credential(verificationId, verificationCode)

    await deviceSignIn(credential)
}

type SignInWithEmailAndPasswordProps = {
	email: string,
	password: string,
	setErr: (err: string) => void,
}

export async function signInWithEmailAndPassword(props: SignInWithEmailAndPasswordProps) {
	const { email, password, setErr } = props

    console.log('Calling signInWithEmailAndPassword', email)

    try {
        const data = await FirebaseAuthentication.signInWithEmailAndPassword({
            email,
            password,
        })

        console.log('data result', data)
        const credential = EmailAuthProvider.credential(email, password)
        await deviceSignIn(credential)
    } catch (err: any) {
        console.log('err result', err)
		if ('code' in err) {
			setErr(err.code)
		} else if ('errorMessage' in err) {
			setErr(err.errorMessage)
		}

        const auth = getFirebaseAuth()

        fetchSignInMethodsForEmail(auth, email)
            .then(result =>{
                console.log("email sign-in methods", result)
                if (result.length && !result.find(provider => provider === 'password')) {
                    const providers = result.map(provider => '"Sign In With ' + ucFirst(provider.match(/^\w+/)![0]) + '"')
                    setErr("Your email is known to AutoRoster, but you didn't create a password — please go back and use " + providers.join(' or ') + " instead.")
                }
            })
            .catch(err => console.log('Error fetching signin methods:', err))
    }
}

//
// Need to do this to work on iOS
//
// https://github.com/capawesome-team/capacitor-firebase/issues/159#issuecomment-1194667877
//

async function deviceSignIn(credential: OAuthCredential | PhoneAuthCredential | EmailAuthCredential) {
    console.log('Device sign in!')
    // const credential = AuthProvider.credential(result.credential?.idToken)
    const auth = getFirebaseAuth()
    console.log("Calling signInWithCredential with credential", credential, "auth", auth)
    await signInWithCredential(auth, credential)
    console.log('Finished device sign in!')
}

/*
 * Linking accounts
 */

//
// TODO - after successful linking, nothing happens and the logo
// doesn't appear in Signons until you restart the app.
//
async function linkWithGoogle(props: ProviderLinkWithProps) {

    console.log("Attempting to link with linkWithGoogle")

    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle()
    console.log("Link result", result)

    // 2. Link on the web layer using the id token
    const credential = GoogleAuthProvider.credential(result.credential?.idToken)

    const auth = getFirebaseAuth()

    return await doLink({
        providerId: 'google.com',
        authUser: auth.currentUser,
        credential,
        setErr: props.setErr,
    })
}

async function linkWithApple(props: ProviderLinkWithProps) {

    console.log("Attempting to link with linkWithApple")

    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple()
    console.log("Link result", result)

    const auth = getFirebaseAuth()

    // 2. Link on the web layer using the id token
    const provider = new OAuthProvider('apple.com')
    const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce,
    })

    return await doLink({
        providerId: 'apple.com',
        authUser: auth.currentUser,
        credential,
        setErr: props.setErr,
    })
}

type LinkWithPhoneNumberProps = SignInWithPhoneNumberProps & ProviderLinkWithProps

export async function linkWithPhoneNumber(props: LinkWithPhoneNumberProps) {
	console.log("Attempting to link with linkWithPhone - TODO", props)

	const { verificationId, verificationCode } = props

	const credential = PhoneAuthProvider.credential(verificationId, verificationCode)

	return await doLink({
		providerId: 'phone',
		authUser: getFirebaseAuth().currentUser,
		credential,
		setErr: props.setErr,
	})
}

// TODO -- doesn't work b/c I haven't done it the capacitor way yet.
/*
async function linkWithFacebook(props: ProviderLinkWithProps) {
    console.log("Attempting to link with linkWithFacebook - TODO", props)
    const result = await FirebaseAuthentication.linkWithFacebook()
    console.log("Link result", result)
	return null
    return result.user
}
*/
type DoLinkProps = {
	providerId: string
	authUser: FirebaseUser | null
	credential: OAuthCredential | PhoneAuthCredential | EmailAuthCredential
	setErr: (err: string|null) => void
}

async function doLink(props: DoLinkProps) {
    const { providerId, authUser, credential, setErr } = props

    console.log('Calling linkWithCredential()', providerId, authUser, credential)

	if (!authUser) {
		setErr("No user to link")
		return null
	}

    //
    // When a user re-links, it seems like you don't need to call the last step -- if you
    // do, you get an error about it already being linked.
    //
    // HMMM I DON"T KNOW ABOUT THAT - I tried to link my phone number with my max_barry@maxbarry.com
    // Apple ID and it seemed to create a separate account?
    //
    if (authUser.providerData.find(provider => provider.providerId === providerId)) {
        console.log('Provider already linked!', providerId)
        return authUser
    }

    try {
        const result = await linkWithCredential(authUser, credential)
        console.log('Successfully linked account:', result)
		setErr(null) // Clear any previous error
        return result.user
    } catch (err: any) {
        console.log("Failure to link account:", err)
		if ('code' in err) {
			setErr(err.code)
		} else if ('errorMessage' in err) {
			setErr(err.errorMessage)
		}
	}

	return null
}

export async function unlinkProvider(providerId: ProviderId) {
    console.log('unlinkProvider()', providerId)
    const auth = getFirebaseAuth()
    if (!auth.currentUser) {
        throw new Error("Unlink failed because no auth.currentUser")
    }
    console.log('with auth, unlink', auth)
    const newUser = await unlink(auth.currentUser, providerId)
    console.log('hey result', newUser)
    return newUser
}

type CreateUserWithEmailAndPasswordProps = {
	email: string
	password: string
	setErr: (err: string|null) => void
}

export async function createUserWithEmailAndPassword(props: CreateUserWithEmailAndPasswordProps) {

	const { email, password, setErr } = props

	try {
		//
		// Create the user
		//
		const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
			email,
			password,
		})
		console.log('createUserWithEmailAndPassword() result:', result)
		//
		// Sign them in
		//
		const credential = EmailAuthProvider.credential(email, password)
		await deviceSignIn(credential)
		console.log('device signin after createUserWithEmailAndPassword')
		//
		// Return the new user
		//
		return result.user
	} catch (err: any) {
        console.log('createUserWithEmailAndPassword err result', typeof err, { err }, 'message', err.errorMessage, 'code', err.code, 'name', err.name, 'stack', err.stack)
		if ('errorMessage' in err) {
			setErr(err.errorMessage)
		} else if ('code' in err) {
			setErr(err.code)
		} else if ('message' in err) {
			setErr(err.message)
		}
	}
	return null
}

const actionCodeSettings = {
	//
	// This seems to depend on Firebase Dynamic Links, which are deprecated. But support told me:
	//
	//    For clarification, please be advised that in order for you to be able to fully utilize and
	//    implement the email link authentication feature in your Firebase project, you will have to
	//    configure FDL as mentioned in the documentation (even though it will be deprecated or
	//    decommissioned). As mentioned in the documentation, this step is required for you to setup
	//    your email authentication feature in your project (hence the error exception you have
	//    encountered).
	//
	//    Note that (as stated in this section of the official FAQs page) your email link
	//    authentication (Using Firebase Authentication) would still work even after the deprecation
	//    of FDL in August 25, 2025. This is because, these two (FDL and Auth) are separate features
	//    and Firebase Auth only depends on the FDL service internally, not relying on the FDL SDK
	//    (which will be deprecated).
	//
	// https://firebase.google.com/docs/auth/web/passing-state-in-email-actions#configuring_firebase_dynamic_links
	//

	url: 'https://autoroster.io/',

	handleCodeInApp: false, 		// We want it to open in a web browser first, then redirect to the app.
									// Otherwise it just opens AutoRoster which does nothing with it.

	dynamicLinkDomain: 'autoroster.page.link',

	android: {
		packageName: 'com.maxbarry.autoroster',
		installApp: true,
	},

	iOS: {
		bundleId: 'com.maxbarry.autoroster',
	},
}

type SendEmailVerificationProps = {
	setErr: (err: string|null) => void
}

export async function sendVerificationEmail(props: SendEmailVerificationProps) {

	const { setErr } = props

	const result = await FirebaseAuthentication.getCurrentUser()
	console.log("sendVerificationEmail -> FirebaseAuthentication.getCurrentUser() says", result)
	if (!result?.user) {
		setErr("Please log in.")
		return false
	}

	if (result?.user?.emailVerified) {
		setErr("Email is already verified.")
		await FirebaseAuthentication.reload()
		return false
	}

	console.log('sendEmailVerification', actionCodeSettings)

	try {
		//
		// This always returns undefined, so we can't tell if it worked.
		//
		await FirebaseAuthentication.sendEmailVerification({
			actionCodeSettings,
		})
	} catch(err: any) {
		console.log('sendEmailVerification err result', typeof err, { err })
		if (err.code === 'auth/too-many-requests') {
			setErr("Too many requests. Please wait before trying again.")
		} else if ('errorMessage' in err) {
			setErr(err.errorMessage)
		} else if ('message' in err) {
			setErr(err.message)
		} else if ('code' in err) {
			setErr(err.code)
		}
		return false
	}
	console.log('sent verification email, probably')
	setErr(null)
	return true
}

type SendPasswordResetEmailProps = {
	email: string
	setErr: (err: string|null) => void
}

export async function sendPasswordResetEmail(props: SendPasswordResetEmailProps) {
	console.log('sendPasswordResetEmail', props, actionCodeSettings)

	const { email, setErr } = props

	//
	// This always returns undefined, so we can't tell if it worked.
	//
	try {
		const result = await FirebaseAuthentication.sendPasswordResetEmail({
			email,
			actionCodeSettings,
		})
		setErr(null)
		console.log('sent password reset email, probably', result)
		return true

	} catch (err: any) {
		console.log('sendPasswordResetEmail err result', typeof err, { err })
		if (err.code === 'auth/user-not-found') {
			setErr("No user found with that email address.")
		} else if ('errorMessage' in err) {
			setErr(err.errorMessage)
		} else if ('message' in err) {
			setErr(err.message)
		} else if ('code' in err) {
			setErr(err.code)
		}
	}
	return false
}

export async function deleteUser() {
	// We dont want to try/catch here because we want any error to propagate up
	// to the UI so we can show the user the error message.
	const result = await FirebaseAuthentication.deleteUser()
	console.log('deleteUser result:', result)

	const auth = getFirebaseAuth()
	console.log('signout with auth!')
	await signOut(auth)
}
