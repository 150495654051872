import './main.css'

import { Haptics, ImpactStyle } from '@capacitor/haptics'
import {
	IonBadge,
	IonContent,
	IonIcon,
	IonLabel,
	IonPage,
    IonRippleEffect,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
	//useIonRouter,
} from '@ionic/react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import React, { PropsWithChildren, useEffect, useState } from 'react'

import {
	scrollGamesTab,
} from './functions'
import {
	tabButtons,
} from './definitions'
import {
	useNewsNotification,
    useUser,
    useUpdateSectionFromUrl,
	useSection,
} from './data'
import Game from './Game'
import Games from './Games'
import Header from './Header'
import Help from './Help'
import JoinClub from './JoinClub'
import { SyncConnector } from './MySeasons'
import Player from './Player'
import Players from './Players'
import Profile from './Profile'
import SaveChanges from './SaveChanges'
import Settings from './Settings'
import Stats from './Stats'
import Teams from './Teams'

const Main = () => (
    <>
        <Teams />
        <Profile />
        <Tabs />
		<SaveChanges />
		<SyncConnector />
    </>
)

const Tabs = () => {

    const user = useUser()

	const newsNotification = useNewsNotification()

	const { season } = useSection()

	const onClickTab = (id: string) => {

		//
		// Scroll to top when you re-tap a tab button.
		//

		if (window.location.pathname === `/${id}`) {
			if (id === 'games') {
				scrollGamesTab()
			} else {
				// This is a bit lame because it doesn't scroll completely
				// to the top -- there's 16px of padding from ion-content
				// left. But I can't figure out how to improve it.
				document.querySelector(`.${id}`)?.scrollIntoView({ behavior: 'smooth' })
			}
		} else {
			//
			// Haptics when we change tabs!
			//

			Haptics.impact({ style: ImpactStyle.Light })
		}
	}

	// console.log('Tabs.js', season, window.location.pathname)

	//
	// Stuttering navigation between games: Related to routing routes, especially
	// wildcard / default routes.
	//
	// At first this seemed to be required:
	//
	//	<Route exact path="/">
	//		<Redirect to="/games" />
	//	</Route>
	//
	// Then I discovered that it only stuttered if I loaded with an empty or invalid
	// URL, and not when it loaded with "/games" or "/players". I fixed by removing
	// default routing from here and putting it into SectionUpdater.js.
	//
    return (
        <IonTabs>
            <IonRouterOutlet id="main">
                <Route path="/settings">
                    <Settings />
                </Route>
                <Route exact path="/players">
                    <Players />
                </Route>
                <Route path="/players/:player">
                    <Player />
                </Route>
                <Route exact path="/games">
                    <Games />
                </Route>
                <Route path="/games/:game">
                    <Game />
                </Route>
                <Route path="/stats">
                    <Tab>
                        <Stats />
                    </Tab>
                </Route>
                <Route path="/help">
					<Tab>
						<Help />
					</Tab>
                </Route>
                <Route path="/join-club/:clubId/:code">
					<JoinClub />
                </Route>
                <Route path="/roster">
                    <OldRosterPath />
                </Route>
				<Route path="/single-roster">
					<ExternalRedirect to="https://v1.autoroster.io/single-roster" />
				</Route>
				<Route path="/trials">
					<ExternalRedirect to="https://v1.autoroster.io/trials" />
				</Route>
            </IonRouterOutlet>

			{/* 'key' is important here to prevent caching issues when switching teams */}
            <IonTabBar slot="bottom" key={`iontabbar-${season || 'no-season'}`}>
                {
                    tabButtons.map(tabButton => {
                        const { id, icon, label } = tabButton

						const showNotification = id === 'help' && newsNotification

                        return (
                            <IonTabButton
                                key={id}
                                tab={id}

								//
								// This seems super-important somehow?? When it was {'/' + id}
								// instead, the "Games" tab button would change its href whenever
								// you visited a game, and then when you clicked on it again,
								// you'd go to some other team. Super weird.
								//
                                href={`/${id}`}

                                onClick={() => onClickTab(id)}
                                disabled={tabButton.requireUser && !user}
                            >
                                <IonIcon
                                    icon={icon}
                                />
								{ showNotification && <IonBadge>1</IonBadge> }
                                <IonLabel>
                                    {label}
                                </IonLabel>
                                <IonRippleEffect />
                            </IonTabButton>
                        )
                    }).filter(v => v)
                }
            </IonTabBar>
        </IonTabs>
    )
}

type TabProps = {
}

const Tab = (props: PropsWithChildren<TabProps>) => (
    <IonPage>
        <IonContent
            className="ion-padding"
        >
            {props.children}
        </IonContent>
        <Header allowTeamChange={true} />
    </IonPage>
)

// Possibly this belongs in SectionUpdater.js instead
const OldRosterPath = () => {
	const location: Location = useLocation()

    const updateSectionFromUrl = useUpdateSectionFromUrl()

	const [ redirect, setRedirect ] = useState<string|undefined>()

    const { pathname, search } = location

    console.log('OldRosterPath', location, pathname, search)
    useEffect(() => {
        console.log("Looks like an old-style /roster URL!")
		const newSection: Section = { }
		search.split(/[?&]/).filter(v => v).forEach(str => {
            const [ key, value ] = str.split('=')
			if (key === 'game' || key === 'season' || key === 'team' || key === 'player') {
				newSection[key] = value
			} else {
				console.error('Unknown key', key, 'in', search)
			}
		})

        setRedirect(newSection.game)

        updateSectionFromUrl(pathname, search)
    }, [ pathname, search, updateSectionFromUrl ])

    if (redirect) {
        console.log('REDIRECT TO', redirect)
        return (
            <Redirect to={`/games/${redirect}`} />
        )
    }

    return null
}

type ExternalRedirectProps = {
	to: string
}

const ExternalRedirect = (props: ExternalRedirectProps) => {

	useEffect(() => {
		window.location.assign(props.to)
	})

	return null
}

export default Main
