import React, { useState, useEffect } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { App } from '@capacitor/app'
import {
    useActiveTabState,
    useMyPlayer,
    useMyPlayerId,
    useMyTeam,
    useMyGame,
	useShowIntroScreen,
    useUpdateSectionFromUrl,
} from './data'
import { labelRound, shortcutToSection, ucFirst } from './util'
import { HOME_URL, isValidTab } from './definitions'

type RedirectType = {
	url?: string
	game?: GameId
}

//
// The type of useLocation() v5 is freaking bizarre.
//

type LocationData = {
	hash: string
	key: string
	pathname: string
	search: string
	state: null
}

type UseLocationData = LocationData | {
	action: string
	location: LocationData
}

const SectionUpdater = () => {

	const locationData: UseLocationData = useLocation()

    const updateSectionFromUrl = useUpdateSectionFromUrl()

    const [ activeTab, setActiveTab ] = useActiveTabState()

	const showIntroScreen = useShowIntroScreen()

	const [ redirect, setRedirect ] = useState<RedirectType>()

	// console.log('SectionUpdater - locData', locationData)

	const { pathname, search } = ('location' in locationData ? locationData.location: locationData)

    useEffect(() => {
        // console.log('Adding appUrlOpen listener.')

        App.addListener('appUrlOpen', data => {
            console.log('* App opened with URL:', data)
            const { url } = data
            if (url) {
                const match = url.match(`^${HOME_URL}/(.*)`)
                if (match) {
                    console.log('Redirecting due to appUrlOpen...')
                    setRedirect({
                        url: match[1],
                    })
                }
            }
        })

		return () => {
			App.removeAllListeners()
		}
    }, [ ])

    //
    // Construct HTML page title, so it looks correct in web bookmarks & navigation
    //
    const team = useMyTeam()
	const game = useMyGame()
    const playerId = useMyPlayerId() || ''
    const player = useMyPlayer(playerId)

	const parts: Array<string> = [ ]

	if (team?.name) {
		parts.push(team.name)
	} else {
		parts.push('AutoRoster for Netball')
	}

    if (activeTab === 'games' && pathname !== '/games') {
        if (game?.round || game?.opponent) {
            const bits = [ ]
            if (game.round) {
                bits.push(labelRound(game.round))
            }
            if (game.opponent) {
                bits.push(`vs ${game.opponent}`)
            }
            parts.push(bits.join(' '))
        }
    } else if (activeTab === 'players' && pathname !== '/players') {
		if (player) {
			parts.push(player.name)
		}
	} else if (pathname) {
        parts.push(ucFirst(pathname.substring(1)))
    }

    //
	// Set HTML page title
	//
    document.title = parts.join(' | ')

    // console.log('SectionUpdater.js', pathname, redirect)

    //
    // Remember which is our active tab, because we want to know when
    // the user re-taps the same tab, and trigger a scroll event.
    //
	useEffect(() => {
		if (pathname) {
			const [ currentTab ] = pathname.substring(1).split(/[?/]/)
			if (currentTab && currentTab !== activeTab) {
				if (isValidTab(currentTab)) {
					console.log('setActiveTab', currentTab)
					setActiveTab(currentTab)
				} else {
					console.error("Weird tab", currentTab)
				}

			}
		}
	}, [ pathname, activeTab, setActiveTab ])

	//
	// Handle shortcuts & redirects
	//
	useEffect(() => {

		//
		// Translate a shortcut like ''?Pa8hWqKf0Y' into
		// 'team=Pa8hQynpLJ15CVECopgx&season=WqK8daFW2axTELADBfws&game=f0YDKMTlTFTuunE06RZq'
		// and send it to updateSectionFromUrl().
		//
		const shortcutToRedirect = async (shortcut: string) => {
			const newSection = await shortcutToSection(shortcut)
			console.log('Shortcut ', shortcut, 'translates to section', newSection)

			const { team, season, game } = newSection

			//
			// Set 'team' and 'season' appropriately
			//
			updateSectionFromUrl('/', `team=${team}&season=${season}`)

			//
			// Send to correct 'game' page
			//
			setRedirect({ game })
		}

		if (pathname === '/') {
			if (search && search.charAt(0) === '?' && !search.match('=')) {

				//
				// Have we been sent a shortcut? If so, redirect
				//
				// e.g. https://autoroster.io/?xFpWaw3b
				//

				console.log('Redirect to shortcut!')
				shortcutToRedirect(search.substring(1))

			} else if (showIntroScreen) {
				console.log('Not redirecting because of showIntroScreen')
			} else {

				console.log("Root! Need a redirect to games")
				setRedirect({ })
			}
		} else {

			// updateSectionFromUrl(pathname, search)
			setRedirect(undefined)

		}

	}, [ pathname, search, updateSectionFromUrl, showIntroScreen ])

	//
	// If sent 'team', 'season', 'game', 'player', then update section
	//
	useEffect(() => {
		updateSectionFromUrl(pathname, search)
	}, [ pathname, search, updateSectionFromUrl ])

	if (redirect) {
		let page

		if (redirect.url) {
			page = redirect.url
		} else {
			page = 'games'
			if (redirect.game) {
				page += `/${redirect.game}`
			}
		}

		console.log("-->REDIRECT", page, search)
		return (
			<Redirect
				to={`/${page}`}
			/>
		)
	}

	return null
}

export default SectionUpdater
